import React, { useEffect, useState } from "react";
import UserWithdrawals from "hooks/dataFetchers/userWithdrawals";
import Timer from "./Timer";
import moment from "moment";
export default function ClaimWithdrawalRow({
  formattedDate,
  item,
  claim,
  claimTimer,
}) {
  const { withdrawals } = UserWithdrawals();
  let [withdrawInfo, setWithdrawInfo] = useState();
  async function withdrawFunc(params) {
    let withdrawlNo = await withdrawals(item?.hygtVestingNumber);
    setWithdrawInfo(withdrawlNo);
  }
  useEffect(() => {
    (item?.hygtVestingNumber === 0 || item?.hygtVestingNumber > 0) &&
      withdrawFunc();
  }, [item]);

  return (
    <div className="bottom-body">
      <div className="trow">
        <p className="widthsmall">
          {moment(item?.createdAt)?.format("DD/MM/YYYY hh:mm A")}
        </p>
        <p className="widthsmall">
          {item?.amount}{" "}
          <span>
            HYDT{" "}
            <img
              src="\assests\hydt-small-icon.svg"
              alt="img"
              className="img-fluid"
            />{" "}
          </span>
        </p>
        <p className="widthsmall">
          {item?.hygtVestingAmount}{" "}
          <span>
            HYGT{" "}
            <img
              src="\assests\hygt-small-icon.svg"
              alt="img"
              className="img-fluid"
            />{" "}
          </span>
        </p>
        <p className="text-capitalize widthsmall">{item?.hygtVestingType}</p>
        {/* <button onClick={() => claimTimer(item)} className="btn-claimTimer">claimTimer</button> */}
        {/* {item?.hygtVestingNumber ? ( */}
        {item?.hygtVestingNumber === 0 || item?.hygtVestingNumber > 0 ? (
          <span className="widthlarge">
            <Timer
              targetEpochTime={withdrawInfo?.chosenVestingDuration}
              claimTimer={claimTimer}
              item={item}
            />
          </span>
        ) : (
          <button onClick={() => claim(item)} className="btn-claim">
            {item?.hygtVestingType == "no vesting"
              ? "Claim HYDT & HYGT"
              : "Claim HYDT"}
          </button>
        )}
      </div>
    </div>
  );
}
