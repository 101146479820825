import React, { useState } from "react";
import copy from "copy-to-clipboard";

export default function CopyReferral({ refLink }) {
  const [copied, setCopied] = useState(false);
  function copyFunc(params) {
    copy(params);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }
  return (
    <div className="inner-field">
      <h6 className="textLowerCase text-truncate">{refLink}</h6>
      {copied ? (
        <a className="btn-copy disable">Copied</a>
      ) : (
        <a onClick={() => copyFunc(refLink)} className="btn-copy">
          Copy
        </a>
      )}
    </div>
  );
}
